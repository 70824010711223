import axios from "../instance";

export default {
    getAll: (branchId, companyId) => { // return axios.api.get(`/customer?warehouseId=${warehouseId}`).then((response) => response.data);
        if (branchId || companyId) {
            return axios.api.get(`/customers?companyId=${companyId}&branchId=${branchId}`).then((response) => response.data);
        } else {
            return axios.api.get(`/customers?companyId=${
                localStorage.getItem('companyId')
            }`).then((response) => response.data);
        }
    },
    getAllByDiscountId: (discountId) => {
        return axios.api.get(`/customers?discountId=${discountId}`).then((response) => response.data);
    },
    filterdata: (query) => { // return axios.api.get(`/customer?warehouseId=${warehouseId}`).then((response) => response.data);
        return axios.api.get(`/customers${query}`).then((response) => response.data);
    },
    getCustomerByRouteId: (id) => { // return axios.api.get(`/customer?warehouseId=${warehouseId}`).then((response) => response.data);
        return axios.api.get(`/customers?routeId=${id}`).then((response) => response.data);
    },
    getAllbyRouteId: (routeId) => {
        return axios.api.get(`/customers?routeId=${routeId}`).then((response) => response.data);
        // return axios.api.get(`/routes`).then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/customers/${id}`).then((response) => response.data);
    },
    search: (body, query) => {
        return axios.api.post(`/customers/search${query}`, body).then((response) => response.data);
    },
    searchpos: (body) => {
        return axios.api.post(`/customers/search`, body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/customers", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/customers/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/customers/${id}`).then((response) => response.data);
    },
    importM3: (code) => {
    return axios.api.get(`/customers/importM3CustomerBatchByCustomerCode?code=${code}`).then((response) => response.data);
    }
};
